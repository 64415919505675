<template>
  <div>
    <tabs>
      <tab
        v-for="(tab, idx) in formsTabs"
        :key="idx"
        :is-active="isActive === idx"
        :is-disabled="idx !== 0 && !hasRecord"
        @click.native="isActive = idx"
      >
        {{ tab.title }}
      </tab>
    </tabs>
    <form-render
      v-if="activeForm"
      :active-form="activeForm"
      :main-form="forms[0] || activeForm"
      :record-data="recordData"
      :form-data="formData"
      :tabs="formsTabs"
      @goToTab="isActive = $event"
    ></form-render>
  </div>
</template>

<script>
export default {
  name: 'FormsCanvas',
  components: {
    FormRender: () => import('@/components/forms/FormRender')
  },
  props: {
    formData: {
      type: Object,
      required: true
    },
    recordData: {
      type: Object
    }
  },
  data() {
    return {
      isActive: 0,
      activeForm: this.formData
    };
  },
  watch: {
    isActive(newVal) {
      this.activeForm = this.forms[newVal];
    },
    formData(newVal) {
      this.activeForm = newVal || null;
    }
  },
  computed: {
    forms() {
      if (this.formData.linkedForms && this.formData.linkedForms.length) {
        const forms = [this.formData];
        this.formData.linkedForms.forEach(form => {
          forms.push(form);
        });
        return forms;
      } else {
        return [];
      }
    },
    formsTabs() {
      const tabs = [];
      this.forms.forEach((form, idx) => {
        tabs.push({
          idx,
          id: form._id,
          title: form.title
        });
      });
      return tabs;
    },
    hasRecord() {
      return this.recordData._id || this.$route.params.recordId;
    }
  }
};
</script>
